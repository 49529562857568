import * as React from 'react';
import { Tooltip, Alert } from '@patternfly/react-core';

export const toExpirationComponent = (t: string | null) => {
  if (t === undefined) {
    return '';
  }

  if (t === '1970-01-01T00:00:00Z' || t === null) {
    return 'Never';
  }

  const exp = new Date(t);
  const e = new Date(exp);
  e.setDate(e.getDate() - 1);

  if (e < new Date()) {
    // within a day of today
    return (
      <Tooltip aria="none" aria-live="polite" content={<div>Expiring soon!</div>}>
        <Alert variant="danger" title={exp.toLocaleString()} />
      </Tooltip>
    );
  }

  return exp.toLocaleString();
};
