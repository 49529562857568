import * as React from 'react';
import {
  PageSection,
  Title,
  Breadcrumb,
  BreadcrumbItem,
  Alert,
  AlertGroup,
  AlertActionCloseButton,
  Split,
  SplitItem,
  Popover,
  Button,
  Modal,
  ModalVariant,
  Form,
  FormGroup,
  TextInput,
  ActionGroup,
  DatePicker,
  TimePicker,
  isValidDate,
  yyyyMMddFormat,
  FlexItem,
  ButtonVariant,
  Flex,
  TextContent,
  Text,
  Tooltip,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { ActionList } from '@app/lib/ActionList';
import { sortable, headerCol } from '@patternfly/react-table';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { AuthContext } from '@app/lib/AuthProvider';
import { UpdateRealizationRequest } from '@mergetb/api/portal/v1/realize_types';
import { useTranslation } from 'react-i18next';
import { toTitleCase } from '@app/lib/util';
import { toExpirationComponent } from '@app/lib/expire';
import { RealizationCard } from './RealizationCard';
import { BallotOutlined, GridViewOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { InfoCircleIcon } from '@patternfly/react-icons';
import { eventEmitter } from '@app/Tutorials/EventEmitter';

const StyledTooltip = styled(Tooltip)({
  '& .pf-v5-c-tooltip__content': {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    border: '1px solid #d2d2d2',
    padding: '10px',
    maxWidth: '500px',
    width: '400px',
    fontSize: '14px',
    lineHeight: '1.4',
    maxHeight: '275px',
  },
  '& .pf-v5-c-tooltip__arrow': {
    display: 'none',
  },
});

const StyledBallotOutlined = styled(BallotOutlined)(({ theme }) => ({
  fontSize: '24px',
  color: 'inherit',
  marginRight: '8px',
  verticalAlign: 'middle',
}));

const StyledGridViewOutlined = styled(GridViewOutlined)(({ theme }) => ({
  fontSize: '24px',
  color: 'inherit',
  marginRight: '8px',
  verticalAlign: 'middle',
}));

const Realizations: React.FunctionComponent = () => {
  const userview_last = (localStorage.getItem('userview') ?? true) == true;

  const conf = React.useContext(GeneralSettingsContext);

  const [alerts, setAlerts] = React.useState([]);
  const { user } = React.useContext(AuthContext);
  const username = user?.username;

  const [viewLabel, setViewLabel] = React.useState('View ' + (userview_last ? 'All' : 'Own'));
  const [userView, setUserView] = React.useState(userview_last);

  const [showRealUpdate, setShowRealUpdate] = React.useState<boolean>(false);
  const [chosenRealName, setChosenRealName] = React.useState<string>('');
  const [expDate, setExpDate] = React.useState<Date>(new Date(Date.now()));

  const { t } = useTranslation();

  const [reload, setReload] = React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isCardView, setIsCardView] = React.useState(false);
  const [noResults, setNoResults] = React.useState(false);

  const columns = [
    { title: toTitleCase(t('realization')), cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Experiment', transforms: [sortable] },
    { title: 'Project', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Creator', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Created', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Expires', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Revision' },
    { title: 'Succeeded' },
    { title: 'Nodes' },
    { title: 'Links' },
  ];

  const handleViewChange = (newView: 'table' | 'card') => {
    setIsCardView(newView === 'card');
  };

  const handleSearchResults = (hasResults: boolean) => {
    setNoResults(!hasResults);
  };

  const mapper = React.useCallback(
    (json) =>
      json.results.map((x) => {
        let r = x.realization;
        let num_nodes = 0;
        let num_links = 0;

        if (x.realization) {
          num_nodes = Object.keys(x.realization.nodes).length;
          num_links = Object.keys(x.realization.links).length;
        }

        if (x.summary) {
          r = x.summary;
          num_nodes = r.numNodes;
          num_links = r.numLinks;
        }

        let success_field: React.ReactFragment = 'Yes';
        if (Object.keys(x.diagnostics.value).length !== 0) {
          let highestLevel = 'info';
          x.diagnostics.value.forEach((d) => {
            switch (d.level.toLowerCase()) {
              case 'error':
                highestLevel = 'error';
                break;
              case 'warning':
                if (highestLevel !== 'error') {
                  highestLevel = 'warning';
                }
                break;
            }
          });

          let variant;
          let btext;
          switch (highestLevel) {
            case 'error':
              variant = 'danger';
              btext = 'Failed';
              break;
            case 'warning':
              variant = 'warning';
              btext = 'Warning';
              break;
            default:
              variant = 'plain';
              btext = 'Info';
              break;
          }

          success_field = (
            <React.Fragment>
              <Popover
                aria-label={toTitleCase(t('realization')) + 'Diagnostics'}
                headerContent={<div>{toTitleCase(t('realization'))} Diagnostics</div>}
                bodyContent={JSON.stringify(x.diagnostics.value)}
              >
                <Button variant={variant}>{btext}</Button>
              </Popover>
            </React.Fragment>
          );
        }

        const retVal = {
          experiment: {
            title: <Link to={`/project/${r.pid}/experiment/${r.eid}`}>{r.eid}</Link>,
            props: { text: r.eid },
          },
          project: {
            title: <Link to={`/project/${r.pid}`}>{r.pid}</Link>,
            props: { text: r.pid },
          },
          creator: {
            title: <Link to={`/user/${r.creator}`}>{r.creator}</Link>,
            props: { text: r.creator },
          },
          created: r.created !== null ? new Date(r.created).toLocaleString() : '',
          expires: toExpirationComponent(r.expires),
          revision: {
            title: <Link to={`/model/${r.pid}/${r.eid}/${r.xhash}`}>{r.xhash.substring(0, 8)}</Link>,
            props: { text: r.xhash.substring(0, 8) },
          },
          succeeded: success_field,
          nodes: num_nodes,
          links: num_links,
          id: r.id,
          eid: r.eid,
          pid: r.pid,
        };

        // The key needs to be the same as the column name in lower case.
        retVal[t('realization').toLowerCase()] = {
          title: <Link to={`/realizations/${r.pid}/${r.eid}/${r.id}`}>{r.id}</Link>,
          props: { text: r.id },
        };

        return retVal;
      }),
    [t]
  );

  let actions = [
    {
      title: toTitleCase(t('relinquish')),
      onClick: (_event, _rowId, rowData) => {
        const rid = rowData.id;
        const eid = rowData.eid;
        const pid = rowData.pid;

        fetch(conf.api + '/realize/realizations/' + pid + '/' + eid + '/' + rid, {
          method: 'DELETE',
          credentials: 'include',
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              return (
                response.text(),
                then((text) => {
                  throw new Error(text);
                })
              );
            }
          })
          .then((_json) => {
            addAlert(
              toTitleCase(t('realization')) +
                ' ' +
                rid +
                '.' +
                eid +
                '.' +
                pid +
                ' ' +
                toTitleCase(t('relinquished')) +
                ' .',
              '',
              'success'
            );
            setReload((reload) => reload + 1);
            eventEmitter.emit('relinquished', { realizationName: `${rid}.${eid}.${pid}` });
          })
          .catch((error) => {
            addAlert('Relinquish Error', error.message, 'danger');
          });
      },
    },
    {
      title: toTitleCase(t('materialize')),
      onClick: (_event, _rowId, rowData) => {
        const rid = rowData.id;
        const eid = rowData.eid;
        const pid = rowData.pid;

        fetch(conf.api + '/materialize/materialize/' + pid + '/' + eid + '/' + rid, {
          method: 'PUT',
          credentials: 'include',
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              return response.text().then((text) => {
                throw new Error(text);
              });
            }
          })
          .then((_json) => {
            const materializationName = `${rid}.${eid}.${pid}`;
            addAlert(toTitleCase(t('materialization')) + ' ' + materializationName + ' started.', '', 'success');
            // Emit the materializationCreated event
            eventEmitter.emit('materializationCreated', { materializationName: materializationName });
            setReload((reload) => reload + 1);
          })
          .catch((error) => {
            console.log('got error:', error);
            addAlert(toTitleCase(t('materialize')) + ' Error', error.message, 'danger');
          });
      },
    },
    {
      title: 'Update',
      onClick: (_event, _rowId, rowData) => {
        const rid = rowData.id;
        const eid = rowData.eid;
        const pid = rowData.pid;

        setChosenRealName(rid + '.' + eid + '.' + pid);
        setShowRealUpdate(true);
      },
    },
  ];

  const toggleView = () => {
    setViewLabel('View ' + (userView === false ? 'All' : 'Own'));
    localStorage.setItem('userview', userView ? '0' : '1');
    setUserView(!userView);
    setReload((reload) => reload + 1);
  };

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem>User</BreadcrumbItem>
        <BreadcrumbItem to={'/users/' + username}>{username}</BreadcrumbItem>
        <BreadcrumbItem>{toTitleCase(t('realizations'))}</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Flex alignItems={{ default: 'alignItemsCenter' }} style={{ gap: '8px' }}>
            <FlexItem>
              <Title headingLevel="h1" size="2xl">
                {toTitleCase(t('realizations'))}
              </Title>
            </FlexItem>
            <FlexItem>
              <StyledTooltip
                content={
                  <TextContent>
                    <Text>
                      A realization is a logical creation of an experiment on an underlying Merge testbed. When an
                      experiment is successfully realized, the resulting realization is an embedding of the experiment
                      topology on the physical resources of one or more resource substrates managed by the Merge Portal.
                      Resources are reserved for the exclusive use of the realization, until the realization is
                      relinquished by the user.
                    </Text>
                  </TextContent>
                }
                position="right"
                enableFlip={false}
                distance={15}
              >
                <Button variant="plain" aria-label="More info for Realizations" style={{ padding: 0 }}>
                  <InfoCircleIcon />
                </Button>
              </StyledTooltip>
            </FlexItem>
          </Flex>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>
          <Button variant="control" aria-label={viewLabel} onClick={toggleView}>
            {viewLabel}
          </Button>
        </SplitItem>
      </Split>
    </PageSection>
  );

  const addAlert = (t, m, v) => {
    setAlerts((prev) => [...prev, { title: t, message: m, variant: v, key: new Date().getTime() }]);
  };

  const removeAlert = (key) => {
    setAlerts([...alerts.filter((el) => el.key !== key)]);
  };

  const notifications = (
    <AlertGroup isToast>
      {alerts.map((a, i) => (
        <Alert
          isExpandable={a.message !== ''}
          variant={a.variant}
          title={a.title}
          key={a.key}
          timeout={8000}
          actionClose={
            <AlertActionCloseButton
              title={a.title}
              variantLabel={`${a.variant} alert`}
              onClose={() => removeAlert(a.key)}
            />
          }
        >
          {a.message}
        </Alert>
      ))}
    </AlertGroup>
  );

  const modalReady = true;

  const submitUpdateReal = () => {
    const start = new Date(Date.now());
    const end = expDate;
    const minutes = (end.getTime() - start.getTime()) / 1000 / 60;
    const ids = chosenRealName.split('.');
    const req = UpdateRealizationRequest.fromJSON({
      project: ids[2],
      experiment: ids[1],
      realization: ids[0],
      duration: {
        when: 'given',
        duration: minutes + 'm',
      },
    });

    // /realize/realizations/{project}/{experiment}/{realization}
    fetch(conf.api + '/realize/realizations/' + ids[2] + '/' + ids[1] + '/' + ids[0], {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(req),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .catch((error) => {
        console.log('got error:', error);
        addAlert('Update Error', error.message, 'danger');
      });

    setReload((reload) => reload + 1);
    setShowRealUpdate(!showRealUpdate);
  };

  const onExpTimeChange = (
    _event: React.FormEvent<HTMLInputElement>,
    _time: string,
    hour?: number,
    minute?: number,
    seconds?: number
  ) => {
    if (isValidDate(expDate) && seconds !== undefined && hour !== undefined && minute !== undefined) {
      const newDate = new Date(expDate);
      newDate.setSeconds(seconds);
      newDate.setHours(hour);
      newDate.setMinutes(minute);
      setExpDate(newDate);
    }
  };

  const onExpDateChange = (_event: React.FormEvent<HTMLInputElement>, _value: string, date?: Date) => {
    if (date) {
      if (isValidDate(date) && isValidDate(expDate)) {
        const newDate = new Date(expDate);
        newDate.setDate(date.getDate());
        newDate.setMonth(date.getMonth());
        newDate.setFullYear(date.getFullYear());
        setExpDate(newDate);
      }
    }
  };

  const expDateRange = (d: Date) => {
    const now = new Date(Date.now());
    if (yyyyMMddFormat(d) < yyyyMMddFormat(now)) {
      return 'Date cannot be in the past';
    }

    const next = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
    if (yyyyMMddFormat(d) > yyyyMMddFormat(next)) {
      return 'Date cannot be more than 30 days from now';
    }
    return '';
  };

  const updateRealModal = (
    <Modal
      isOpen={showRealUpdate}
      onClose={() => setShowRealUpdate(false)}
      variant={ModalVariant.medium}
      aria-label="Update Realization"
    >
      <>
        <Title headingLevel="h1" size="2xl">
          Update Realization
        </Title>
        <Form>
          <FormGroup label="Name" fieldId="real-name">
            <TextInput type="text" aria-label="Realization Name" isDisabled value={chosenRealName} />
          </FormGroup>
          <FormGroup label="Expiration Date" aria-label="Expiration Date">
            <DatePicker onChange={onExpDateChange} validators={[expDateRange]} />
          </FormGroup>
          <FormGroup label="Expiration Time" aria-label="Expiration Time">
            <TimePicker onChange={onExpTimeChange} />
          </FormGroup>
          <ActionGroup>
            <Button variant="primary" onClick={submitUpdateReal} isDisabled={!modalReady} isAriaDisabled={!modalReady}>
              Submit
            </Button>
          </ActionGroup>
        </Form>
      </>
    </Modal>
  );

  let url = conf.api + '/realize/realizations?summary=true';
  if (userView === false) {
    url += '&filter=ByAll';
  }

  return (
    <React.Fragment>
      {alerts.length !== 0 && notifications}
      {updateRealModal}
      {crumbs}
      {header}
      <PageSection>
        <Flex alignItems={{ default: 'alignItemsCenter' }} spaceItems={{ default: 'spaceItemsMd' }}>
          <FlexItem>
            <Button
              variant={isCardView ? ButtonVariant.control : ButtonVariant.primary}
              onClick={() => handleViewChange('table')}
              aria-label="Table view"
              style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <StyledBallotOutlined />
              <span style={{ lineHeight: 1 }}>Table</span>
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={isCardView ? ButtonVariant.primary : ButtonVariant.control}
              onClick={() => handleViewChange('card')}
              aria-label="Card view"
              style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <StyledGridViewOutlined />
              <span style={{ lineHeight: 1 }}>Card</span>
            </Button>
          </FlexItem>
        </Flex>
        <div style={{ overflowX: 'auto', marginTop: '16px' }}>
          <ActionList
            kind="Realizations" // Make sure this is exactly "Realizations"
            columns={columns}
            url={url}
            actions={actions}
            mapper={mapper}
            reload={reload}
            reloadTrigger={0}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            onSearchResults={handleSearchResults}
            isCardView={isCardView}
            CardComponent={RealizationCard}
          />
        </div>
        {noResults && (
          <Alert variant="info" title="No realizations found" isInline>
            No realizations match your search criteria. Try adjusting your search.
          </Alert>
        )}
      </PageSection>
    </React.Fragment>
  );
};

export { Realizations };
