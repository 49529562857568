import * as React from 'react';
import { useParams } from 'react-router-dom';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { useFetch } from 'use-http';

import {
  PageSection,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Spinner,
  Bullseye,
  Alert,
  Grid,
  GridItem,
} from '@patternfly/react-core';
import {
  GetRealizationResponse,
  RealizationResult,
  Realization as PortalRlz,
} from '@mergetb/api/portal/v1/realize_types';
import { headerCol, sortable } from '@patternfly/react-table';
import { ActionList } from '@app/lib/ActionList';
import { DetailEntry, DetailsTable } from '@app/lib/DetailsTable';
import { Link } from 'react-router-dom';
import { TaskStatusTable } from '@app/lib/TaskStatusTable';
import { GRPCError } from '@app/lib/error';

import { useTranslation } from 'react-i18next';
import { toTitleCase } from '@app/lib/util';
import { toExpirationComponent } from '@app/lib/expire';

const Realization: React.FunctionComponent = () => {
  const { pid, eid, rid } = useParams();
  const conf = React.useContext(GeneralSettingsContext);
  const { t } = useTranslation();

  const url = conf.api + '/realize/realizations/' + pid + '/' + eid + '/' + rid;
  const st_url = url + '?statusMS=-1';
  const st_getter = (data) => {
    return data.status;
  };

  const options = { credentials: 'include' };
  const { loading, error, get, response } = useFetch(url, options, []);
  const [loadError, setLoadError] = React.useState<GRPCError>();
  const [result, setResult] = React.useState<RealizationResult>();

  const load = React.useCallback(async () => {
    const resp = await get();
    if (response.ok) {
      setResult(GetRealizationResponse.fromJSON(resp).result);
    } else {
      setLoadError(resp);
    }
  }, [get, response]);

  React.useEffect(() => {
    load();
  }, [load]);

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem to="/project">Projects</BreadcrumbItem>
        <BreadcrumbItem to={'/project/' + pid}>{pid}</BreadcrumbItem>
        <BreadcrumbItem to="/experiment">Experiments</BreadcrumbItem>
        <BreadcrumbItem to={'/project/' + pid + '/experiment/' + eid}>{eid}</BreadcrumbItem>
        <BreadcrumbItem to="/realizations">{toTitleCase(t('realization'))}</BreadcrumbItem>
        <BreadcrumbItem>{rid}</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const details: Array<DetailEntry> = result?.realization
    ? [
        { label: 'Creator', value: result.realization.creator },
        {
          label: 'Created',
          value: result.realization.created == null ? '' : new Date(result.realization.created).toUTCString(),
        },
        {
          label: 'Expires',
          value: toExpirationComponent(result.realization.expires ? result.realization.expires.toLocaleString() : null),
        },
        {
          label: 'Revision',
          value: (
            <Link
              to={'/model/' + result.realization.pid + '/' + result.realization.eid + '/' + result.realization.xhash}
            >
              {result.realization.xhash.substring(0, 8)}
            </Link>
          ),
        },
        { label: 'Infrapod Server', value: result.realization.infranet?.InfrapodServer },
      ]
    : [];

  return (
    <React.Fragment>
      {crumbs}
      <PageSection>
        <Grid hasGutter>
          <GridItem>
            <Card id={`${rid}-card-id`}>
              <CardHeader id={`${rid}-cardheader-id`}>
                <CardTitle>{toTitleCase(t('realization'))} Node Details</CardTitle>
              </CardHeader>
              <CardBody>
                {error && !loadError && (
                  <Alert variant="danger" title="Error">
                    Error loading realization data
                  </Alert>
                )}
                {error && loadError && (
                  <Alert variant="danger" title="Response Error">
                    <pre>{loadError.message}</pre>
                  </Alert>
                )}
                {loading && (
                  <Bullseye>
                    <Spinner size="sm" />
                  </Bullseye>
                )}
                {result && <RealizationNodes rlz={result.realization} diag={result.diagnostics} active={false} />}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem>
            <Card id={rid + '-rlzdeets-id'}>
              <CardHeader id={rid + '-rlzdeedzhdr'}>
                <CardTitle>{toTitleCase(t('realization'))} Details</CardTitle>
              </CardHeader>
              <CardBody>{details.length > 0 && <DetailsTable label="rlzdetails" entries={details} />}</CardBody>
            </Card>
          </GridItem>
          <GridItem>
            <Card id="statusCard">
              <CardHeader>
                <CardTitle id="statusCardTitle">Status</CardTitle>
              </CardHeader>
              <CardBody>
                <TaskStatusTable
                  kind={rid + '-tst'}
                  url={st_url}
                  getter={st_getter}
                  ongoingfrequency={2000}
                  completedfrequency={60000}
                  scalingfrequency={1.0 / 10.0}
                />
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </PageSection>
    </React.Fragment>
  );
};

type RlzNodesProps = {
  rlz: PortalRlz;
  diag: unknown;
  active: boolean;
};

const RealizationNodes: React.FunctionComponent<RlzNodesProps> = ({ rlz, diag, active }) => {
  if (rlz.complete) {
    const cells = [
      { key: 'name', title: 'Name', cellTransforms: [headerCol()], transforms: [sortable] },
      { key: 'experimentAddresses', title: 'Experiment Addresses' },
      { key: 'infranetAddress', title: 'Infranet Address' },
      { key: 'kind', title: 'Kind' },
      { key: 'resource', title: 'Resource' },
      { key: 'image', title: 'Image' },
      { key: 'facility', title: 'Facility' },
    ];

    const formatAddrs = (sockets) => {
      let val = [];
      sockets.forEach((s) => {
        val = [...val, ...s.addrs];
      });
      return val.join(', ');
    };

    const rows = Object.keys(rlz.nodes).map((n) => ({
      name: rlz.nodes[n].node?.id,
      experimentAddresses: formatAddrs(rlz.nodes[n].node?.sockets),
      infranetAddress: rlz.nodes[n].infranetAddr,
      kind: rlz.nodes[n].kind,
      resource: rlz.nodes[n].resource?.id,
      image: rlz.nodes[n].node?.image?.value,
      facility: rlz.nodes[n].facility,
    }));

    const actions = [
      { title: 'Console', disabled: true },
      { title: 'Reboot', disabled: true },
    ];

    return (
      <ActionList
        kind="Realization"
        columns={cells}
        rows={rows}
        actions={active ? actions : []}
        reloadTrigger={0}
        searchTerm=""
        setSearchTerm={() => {}}
        onSearchResults={() => {}}
        isCardView={false}
        CardComponent={null}
      />
    );
  }
  // otherwise, print the diagnostics
  const cells = [
    { title: 'Level', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Message' },
    { title: 'Host' },
    { title: 'Guest' },
  ];

  const rows = diag.value.map((v) => [v.level, v.message, v.host, v.guest]);

  return <ActionList kind="Diagnostics" columns={cells} rows={rows} actions={active ? actions : []} />;
};

export { Realization, RealizationNodes };
